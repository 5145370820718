import { render, staticRenderFns } from "./profitData.vue?vue&type=template&id=780e7138&scoped=true&"
import script from "./profitData.vue?vue&type=script&lang=js&"
export * from "./profitData.vue?vue&type=script&lang=js&"
import style0 from "./profitData.vue?vue&type=style&index=0&id=780e7138&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "780e7138",
  null
  
)

export default component.exports