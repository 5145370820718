<template>
  <div class="ad-consume padding-20 box-shadow-light-grey">
    <!-- 条件搜索 -->
    <div class="filter-box">
      <!-- <h4 class="filter-title">今日广告</h4> -->
      <div class="row">
        <!-- 单独用户 -->
        <el-select
          v-model="params.adminUserId"
          filterable
          clearable
          placeholder="用户名称"
        >
          <el-option
            v-for="item in userList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
        <!-- 字段选择 -->
        <select-field
          :fieldsData="fieldsData"
          :originFeild="originFeild"
          :EchoField="sendField"
          @fieldChange="handleFieldChange"
        />
        <el-button
          type="primary"
          @click="handlePageChange(1)"
          style="margin-left: 10px"
          :loading="loading"
        >
          搜索
        </el-button>
        <el-button type="info" @click="handleReset" :loading="loading">
          重置
        </el-button>
      </div>
    </div>
    <!-- 汇总数据 -->
    <div>
      <div
        class="statistics-card"
        v-for="(item, index) in totalData"
        :key="index"
      >
        <div class="data-label">{{ item.label }}</div>
        <div class="statistic-num-text">
          <span class="statistic-num">{{ item.value || 0 }}</span>
        </div>
      </div>
    </div>
    <!-- 表格数据 -->
    <el-table
      header-row-class-name="table-header"
      ref="userConsumeTable"
      class="consume-table"
      :data="staticDataList"
      style="width: 100%"
      height="570px"
      v-loading="loading"
      empty-text="暂无数据"
      :row-class-name="tableRowClassName"
      :span-method="objectSpanMethod"
      @sort-change="handleSortChange"
    >
      <el-table-column
        v-for="(item, index) in filFields"
        :key="index"
        :prop="item.fieldCode"
        :label="item.fieldName"
        :sortable="sortableType(item)"
        :align="filAlign(item)"
        :min-width="item.width + 'px' || '100px'"
      >
        <template slot-scope="scope">
          <div v-if="item.hasChild">
            <div v-for="(item2, index2) in item.children" :key="index2">
              {{ item2.fieldName }}:
              <span :style="'color:' + item.fontColor">{{
                scope.row[item2.fieldCode]
              }}</span>
            </div>
          </div>
          <span :style="'color:' + item.fontColor" v-else>{{
            scope.row[item.fieldCode] | sortField(item)
          }}</span>
        </template>
      </el-table-column>
      <el-table-column
        v-for="(head, index) in extraHeader"
        :key="head"
        :label="head"
        prop="head"
      >
        <template slot-scope="scope">
          <span class="green-num">{{ scope.row.profitList[index] }}</span>
        </template>
      </el-table-column>
    </el-table>
    <!-- 静态分页 -->
    <el-pagination
      style="margin-top: 10px; text-align: right"
      background
      @current-change="handleStaticPage"
      :current-page="staticPage"
      :page-size="staticPageSize"
      layout="total, prev, pager, next, jumper"
      :total="staticPageTotal"
    >
    </el-pagination>
    <!-- 公共抽屉 -->
  </div>
</template>

<script>
import { formatThousandsDots } from "@/assets/js/utils";
import SelectField from "@/components/SelectField";
import { getFinanceProfit, getFields } from "@/api/statics";
import {
  yesterdayOptions,
  labelList,
  pickerOptions,
} from "@/assets/js/options";
import { mapActions, mapGetters } from "vuex";
let timer = null;
export default {
  name: "userConsume",
  components: {
    SelectField,
  },
  computed: {
    filFields: function () {
      return this.showField.filter(function (item) {
        return item.autoFields !== 1;
      });
    },
    // 排序处理
    sortableType() {
      return function (value) {
        if (value.autoFields === 0) {
          return false;
        } else if (value.autoFields === 2 || value.autoFields === 4) {
          return true;
        } else if (value.autoFields === 1 || value.autoFields === 3) {
          return "custom";
        }
      };
    },
    // 文字对齐处理
    filAlign() {
      return function (value) {
        switch (value.fontAlign) {
          case 0:
            return "left";
          case 1:
            return "center";
          case 2:
            return "right";
          default:
            return "center";
        }
      };
    },
    ...mapGetters(["userList"]),
  },
  filters: {
    sortField: function (value, item) {
      if (item.autoFields === 4 || item.autoFields === 5) {
        // 报错处理
        if (value !== undefined) {
          return formatThousandsDots(value.toString());
        }
      } else {
        return value;
      }
    },
  },
  data() {
    return {
      params: {},
      bookList: null,
      fieldsData: [],
      originFeild: [],
      selectFields: null,
      selectAllFields: false,
      dataList: [],
      totalData: [],
      loading: false,
      sortName: null,
      sortDesc: null,
      showRecharge: false,
      yesterdayOptions,
      labelList,
      sendField: [],
      showField: [],
      tmpShowField: [],
      showCommonDrawer: false,
      showTemplate: null,
      nextParams: {},
      pickerOptions,
      page: 1,
      totalPage: 1,
      extraHeader: [],
      spanArr: [],
      pos: 0,
      staticDataList: [],
      staticPage: 1,
      staticPageTotal: 0,
      staticPageSize: 10,
    };
  },
  methods: {
    // 静态分页
    handleStaticPage(page) {
      this.staticPage = page;
      // this.staticDataList.splice(0, this.staticDataList.length);
      this.staticDataList = this.dataList.slice(
        (this.staticPage - 1) * this.staticPageSize,
        this.staticPage * this.staticPageSize
      );
    },
    // 合并同名数据
    tableRowClassName({ rowIndex }) {
      if (this.spanArr[rowIndex] !== 0 && rowIndex !== 0) {
        return "table-top-red";
      }
      return "";
    },
    objectSpanMethod({ rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        const _row = this.spanArr[rowIndex];
        const _col = _row > 0 ? 1 : 0;
        return {
          rowspan: _row,
          colspan: _col,
        };
      }
    },
    getSpanArr(data) {
      // spanArr和pos需要定义
      this.spanArr = [];
      // this.contentSpanArr = [];
      for (let i = 0; i < data.length; i++) {
        if (i === 0) {
          this.spanArr.push(1);
          this.pos = 0;
        } else {
          // 判断当前元素与上一个元素是否相同 (type要根据要合并的元素更改)
          if (data[i].adminUserId === data[i - 1].adminUserId) {
            this.spanArr[this.pos] += 1;
            this.spanArr.push(0);
          } else {
            this.spanArr.push(1);
            this.pos = i;
          }
        }
      }
    },
    ...mapActions({
      addRouterHistory: "addRouterHistory",
    }),
    // 排序处理
    handleSortChange({ column, prop, order }) {
      if (column.sortable !== "custom") {
        return;
      }
      this.sortName = prop;
      this.sortDesc = order === "descending";
      this.params.sortName = this.sortName;
      this.params.sortDesc = this.sortDesc;
      this.handlePageChange(1);
    },
    // 重置操作
    handleReset() {
      this.params.adminUserId = null;
      this.sortName = null;
      this.sortDesc = null;
      this.sendField = [];
      this.showField = this.originFeild;
      this.tmpShowField = [];
      this.handlePageChange(1);
    },
    // 分页操作
    handlePageChange(page) {
      this.page = page;
      this.getData();
    },
    // 选择字段变化
    handleFieldChange(send, show) {
      // if (value.length === 0) {
      //   this.showField = this.originFeild;
      // } else {
      //   this.showField = value;
      // }
      // 请求格式field
      this.sendField = send;
      // 展示格式field
      this.tmpShowField = show;
      this.$nextTick(() => {
        this.$refs.userConsumeTable.doLayout();
      });
    },
    // 请求前处理字段
    handleFields(item) {
      if (item.length === 0) {
        return null;
      }
      let tmpSend = [];
      const _that = this;
      tmpSend = item.reduce(function (a, b) {
        return a.concat(b);
      });
      // 判断勾选项 推入其下所有子级
      for (let i = 0; i < tmpSend.length; i++) {
        const flagIndex = _that.originFeild.findIndex((item2) => {
          return item2.fieldCode === tmpSend[i];
        });
        if (flagIndex !== -1 && "children" in _that.originFeild[flagIndex]) {
          _that.originFeild[flagIndex].children.forEach((item3) => {
            tmpSend.push(item3.fieldCode);
          });
        }
      }
      // 去重
      tmpSend = Array.from(new Set(tmpSend));
      return tmpSend.join(",");
    },
    // 广告数据获取
    getData(flag) {
      this.loading = true;
      let tmpFields = null;
      if (this.sendField.length > 0) {
        tmpFields = this.handleFields(this.sendField);
      } else {
        this.showField = this.originFeild;
      }
      let tmpform = {};
      // 如果是创建过程 参数默认取缓存参数 否则使用筛选参数
      tmpform = {
        adminUserId: this.params.adminUserId,
        sortName: this.sortName,
        sortDesc: this.sortDesc,
        showFieldCodes: tmpFields,
      };
      getFinanceProfit(tmpform)
        .then((res) => {
          // 如果用户选择过字段则表格展示用户选择字段
          if (this.tmpShowField.length > 0) {
            this.showField = this.tmpShowField;
          }
          this.dataList = res.list;
          this.staticDataList = this.dataList.slice(0, this.staticPageSize);
          this.staticPage = 1;
          this.staticPageTotal = this.dataList.length;
          this.getSpanArr(res.list);
          this.totalData = res.showSumFields;
          // 是否选择了动态列表
          if (tmpform.showFieldCodes) {
            this.extraHeader = tmpform.showFieldCodes.includes("profitList")
              ? res.headers
              : [];
          } else {
            this.extraHeader = res.headers;
          }
          // this.extraHeader = res.headers;
          // 缓存参数
          this.addRouterHistory({
            path: this.$route.path,
            params: {
              ...tmpform,
              cacheShowField: this.showField,
              cacheSendField: this.sendField,
              selectTime: this.params.selectTime,
            },
          });
        })
        .finally(() => (this.loading = false));
    },
    // 获取字段
    handleGetFeilds() {
      getFields({ dataType: "profitAmount" }).then((res) => {
        this.originFeild = res;
        const tmpdata = [];
        for (let i = 0; i < res.length; i++) {
          tmpdata.push({
            fieldName: res[i].fieldName,
            fieldCode: res[i].fieldCode,
          });
        }
        this.fieldsData = tmpdata;
        if (this.showField.length === 0) {
          this.showField = res;
        }
        this.selectAllFields = true;
        this.getData("created");
        // 判断字段所选长度是否为0 是则全选 否则不全选
      });
    },
    // 处理字段
  },
  created() {
    // 缓存获取
    const tmpIndex = this.$store.state.routerHistory.findIndex(
      (item) => item.path === this.$route.path
    );
    // 若存在缓存
    if (tmpIndex !== -1) {
      this.params = this.$store.state.routerHistory[tmpIndex].params;
      // 获取缓存展示字段
      this.showField =
        this.$store.state.routerHistory[tmpIndex].params.cacheShowField;
      // 获取缓存发送前格式字段
      this.sendField =
        this.$store.state.routerHistory[tmpIndex].params.cacheSendField;
    }
    // this.params.queryDate = formatTime(new Date(), "yyyy-MM-dd");
    this.handleGetFeilds();
    // this.getData()
    clearInterval(timer);
    // timer = setInterval(this.getData, 1000 * 5 * 60);
  },
  beforeCreate() {
    this.$options.components.CommonDrawer =
      require("@/components/commonDrawer").default;
  },
  // beforeDestroy() {
  //   clearInterval(timer);
  //   timer = null;
  // },
};
</script>

<style lang="scss" scoped>
.ad-consume {
  background-color: #fff;
  border-radius: 6px;
}
// 筛选条件框
.filter-box {
  border-radius: 6px;
  // border: 1px solid #eceff2;
  .filter-title {
    color: $main-text;
    margin-bottom: 20px;
    .iconfont {
      font-size: 12px !important;
    }
  }
}
.consume-table {
  .adId {
    text-decoration: underline;
    cursor: pointer;
  }
  .min-img {
    width: 70px;
    height: 70px;
  }
}
</style>
